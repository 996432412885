import {useQuery} from "@redux-requests/react";
import React, {memo, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getSettings} from "../api/api";
import MainLogo from "../component/MainLogo";
import {handleApi} from "../helper/helper";
import {getFlags} from "../redux/action/apiAction";
import DV from "../variables/DV";
import {LIST_DOMAIN} from "../variables/staticValue";
import {getPermanentIP} from "../variables/utils";

function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "_" + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

const FlagrProvider = (props) => {
  const {children} = props;
  const [loading, setLoading] = useState(true);
  const {backgroundColor} = LIST_DOMAIN[DV.partner];
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {data: settings} = await handleApi(getSettings()) ?? {};

      dispatch(getFlags({
        flagKeys: [
          "CHANNEL_TIKTOK_BUSINESS_ENABLED",
          "CHANNEL_TIKTOK_ENABLED",
          "CHANNEL_FANPAGE_ENABLED",
          "CHANNEL_SHOPEE_ENABLED",
          "CHANNEL_CALL_CENTER_ENABLED",
          "CHANNEL_WEBSITE_ENABLED",
          "CHANNEL_ZALOOA_ENABLED",
          "CHANNEL_INSTAGRAM_ENABLED",
          "CHANNEL_MANUAL_ENABLED",
          "CHANNEL_EMAIL_ENABLED",
          "CHANNEL_GROUP_ENABLED",
          "CHANNEL_PERSONAL_FACEBOOK_ENABLED",
          "CHANNEL_GOOGLEBM_ENABLED",
          "CHANNEL_APPLEBM_ENABLED",
          "CHATBOT_FLAT_SCENARIO_ENABLED",
          "SALE_ENABLED",
        ],
        enableDebug: false,
        entities: [{
          entityID: `${DV.owner.id}`,
          entityType: "user",
          entityContext: flattenObject({
            ipinfo: await getPermanentIP(),
            company_id: DV.company.id,
            settings,
            domain: window.location.hostname,
            company_registered_date: 11,
          }),
        }],
      }));

      setLoading(false);
    })();
  }, []);

  if (loading)
    return (
      <div
        className="flex-center-col"
        style={{height: "100vh", background: "white", backgroundColor}}>
        <MainLogo/>
      </div>
    );

  return children;
};

export const useFlagKey = (key, defaultValue = false) => {
  const {data, error} = useQuery({type: getFlags});

  const checkIsEnable = (key, defaultValue) => {
    if (!key) return true;

    if (!!error) return defaultValue;

    if (!data?.evaluationResults) return true;

    const flagItem = data.evaluationResults.find(item => item.flagKey === key);

    if (flagItem?.variantKey === "on") return true;
    if (flagItem?.variantKey === "off") return false;
    return defaultValue;
  };

  return {
    isEnable: checkIsEnable(key, defaultValue),
    checkIsEnable,
    flags: data?.evaluationResults,
  };
};

export const FlagKeyCheck = ({flagKey, flagKeyDefaultValue, children}) => {
  const {isEnable} = useFlagKey(flagKey, flagKeyDefaultValue);

  if (isEnable) return children;
  return null;
};

export default memo(FlagrProvider);
